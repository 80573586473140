@import url(https://fonts.googleapis.com/css2?family=Golos+Text:wght@400;500;600&family=Indie+Flower&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: rgb(233, 236, 226);
}


.conteiner {
  text-align: center;
  width: 100%;
  height: 100vh;
  margin: 0;
  font-family: 'Golos Text', sans-serif;
  color: #2b29a0;
  font-size: 12px;
}


.header-image {
  background-image: url(/static/media/bannerLab.67aec72b.png);
  width: 90%;
  height: 50vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto
}
 

.logo {
  height: 50px;
  width: 100%;
  background-image: url(/static/media/logo-laranja.70d5019c.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

h1 {
  letter-spacing: 1px;
}


.versc {
  margin: 0 auto;
  width: 80%;
  color:#2b29a0 !important;
}


h2 {
  font-family: 'Indie Flower', cursive !important;
}


.buttons-container{
  margin-top: 20px;
  width: 100%;
}


.buttons-container h1 {
  margin: 0 !important;
}


.buttons {
  /* border-color: rgb(247, 96, 12)!important; */
  font-family: 'Indie Flower', cursive !important;
  font-size: large !important;
  background-image: url(/static/media/botao.d3493cd1.png);
  color: rgb(247, 96, 12) !important;
  font-weight: bolder !important;
  margin-top: 10px !important;
  margin-bottom: 40px !important;
  width: 60% !important;
  background-position: center;
  height: 75px !important;
  background-size: contain;
  border: none !important;
  line-height: 25px !important;
  background-repeat: no-repeat!important;
}

.copy {
  margin-top: 30px;
  color: rgb(247, 96, 12) ;
}
